<template>
  <div>
    <b-modal
      id="modal-deactivate"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Deactivate')} ${editId.supplierName}`"
      hide-footer
    >

      <p>{{ $t('YouAreDeactivating') }} <b>{{ editId.supplierName }}</b> ,{{ $t('ChooseNewSupplier') }}</p>
      <div class="deactivate-modal__select">
        <b-form-select
          v-model="selected"
          text-field="supplierName"
          value-field="supplierId"
          :options="getSuppliers"
        />
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Deactivate') }}
          </b-button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: Object,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 15,
      totalItems: '',
      selected: null,
    }
  },
  computed: {
    ...mapGetters(['getSuppliers']),
  },

  mounted() {
    this.loadSuppliers({ pageNumber: this.page, pageSize: this.pageSize })
  },
  methods: {
    ...mapActions(['loadSuppliers']),
    onSubmit() {
      this.$emit('objData', this.selected)
      this.$refs.modal.hide()
    },
    onCancel() {
      this.selected = null
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
</style>
