<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab
          :title="$t('Report')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MaterialSuppliers") }}</strong>
          </template>
          <div
            class="table__header "
            style="margin-bottom: 15px !important; margin-top: 15px; justify-content: space-between;"
          >
            <div v-if="isActive">
              <button
                v-if="getLoggedInUser.role == 'StockManagerMaterialVariant' && client == 'WaloneFashion'"
                v-b-modal.modal-1
                class="button"
              >
                <b-icon-plus />
                {{ $t('AddNewSupplier') }}
              </button>
              <button
                v-else-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                v-b-modal.modal-1
                class="button"
              >
                <b-icon-plus />
                {{ $t('AddNewSupplier') }}
              </button>
            </div>
            <div style="display: flex; justify-content: flex-end; gap: 1%; width: 100%">
              <button
                class="button"
                @click="activeSupplier"
              >
                {{ $t('Active') }}
              </button>
              <button
                class="button"
                @click="inActiveSupplier"
              >
                {{ $t('Inactive') }}
              </button>
            </div>
            <div class="filtering">
              <!-- v-b-modal.modal-1 -->

              <div class="search-toggle">

                <p>{{ $t('SearchBy') }}</p>
                <div>
                  <p
                    :class="{ active2: !toggleSarch }"
                    @click="showSName"
                  >
                    {{ $t('SupplierName') }}
                  </p>
                  <p
                    :class="{ active2: toggleSarch }"
                    @click="showCName"
                  >
                    {{ $t('ContactName') }}
                  </p>
                </div>
              </div>
              <div v-if="show == true">
                <div>
                  <div class="black">
                    <b-icon-search
                      v-if="searchBySupplierName == ''"
                      class="search1"
                      style="position: absolute; left: 5px; top: 12px"
                    />
                    <b-icon-x-circle
                      v-else-if="searchBySupplierName != '' && !noSearch"
                      class="search1"
                      style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                      @click="searchBySupplierName = ''"
                    />
                    <input
                      v-model="searchBySupplierName"
                      type="text"
                      name="search-box"
                      autocomplete="off"
                      :placeholder="$t('SupplierName')"
                      :style="getFilteredSuppliersBySN.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
                      @keyup="inputChanged"
                      @keydown.down="onArrowDown"
                      @keydown.up="onArrowUp"
                    >
                  </div>
                  <div
                    v-if="getFilteredSuppliersBySN.length > 0 && noSearch"
                    ref="scrollContainer"
                    class="dropdrop"
                    style="
                    display: inline-block;
                    overflow: auto;
                    position: absolute;
                    background: white;
                    width: 229px;
                    box-shadow: none;"
                    :style="getFilteredSuppliersBySN.length > 7 ? 'height: 210px' : 'height: auto'"
                  >
                    <div
                      v-for="(result, index) in getFilteredSuppliersBySN"
                      :key="index"
                      ref="options"
                      style="cursor: pointer"
                      class="search-item"
                      :class="{ 'is-active': index === arrowCounter }"
                      @click="searchBySn(result); noSearch = false"
                    >
                      <p style="margin: 0">
                        {{ result }}
                      </p>
                    </div>
                    <!-- <div
                  v-if="getFilteredSuppliersBySN.length == 0"
                  class="search-item"
                >
                  <p>Sorry. No Results.</p>
                </div> -->
                  </div>
                </div>
              </div>
              <div v-if="show == false">
                <div class="black">
                  <b-icon-search
                    v-if="searchByContactName == ''"
                    class="search2"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchByContactName != '' && !noSearchTwo"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchByContactName = ''"
                  />
                  <input
                    v-model="searchByContactName"
                    type="text"
                    name="search-box"
                    :placeholder="$t('ContactName')"
                    :style="getFilteredSuppliersByCN.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChanged2"
                    @keydown.down="onArrowDown2"
                    @keydown.up="onArrowUp2"
                  >
                </div>
                <div
                  v-if="getFilteredSuppliersByCN.length > 0 && noSearchTwo"
                  ref="scrollContainer2"
                  class="dropdrop"
                  style="
                  display: inline-block;
                  overflow: auto;
                  position: absolute;
                  background: white;
                  width: 229px;
                  box-shadow: none;"
                  :style="getFilteredSuppliersByCN.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getFilteredSuppliersByCN"
                    :key="index"
                    ref="options2"
                    style="cursor: pointer"
                    :class="{ 'is-active': index === arrowCounter2 }"
                    class="search-item"
                    @click="searchByCn(result); noSearchTwo = false"
                  >
                    <p style="margin: 0">
                      {{ result }}
                    </p>
                  </div>
                  <!-- <div
                v-if="getFilteredSuppliersByCN.length == 0"
                class="search-item"
              >
                <p>Sorry. No Results.</p>
              </div> -->
                </div>
              </div>
            </div>

          </div>
          <div
            style="width: 100%;  padding-top: 0px; margin-top: 0px;"
            class="scroll"
          >
            <table
              ref="exportable_table"
              class="team_table"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t('No') }}
                  </th>
                  <th>
                    {{ $t('SupplierName') }}
                  </th>
                  <th>
                    {{ $t('ContactPerson') }}
                  </th>
                  <th>
                    {{ $t('ContactTitle') }}
                  </th>
                  <th>
                    {{ $t('PhoneNumber') }}
                  </th>
                  <th>
                    {{ $t('SupplierEmail') }}
                  </th>
                  <th>
                    {{ $t('WebPage') }}
                  </th>
                  <th>
                    {{ $t('Address') }}
                  </th>
                  <!-- <th style="padding: 10px;text-align: left;">
                DoorNumber
              </th> -->
                  <!-- <th style="padding: 10px;text-align: left;" /> -->
                  <!-- <th style="padding: 10px;text-align: left;" /> -->
                  <!-- <th style="padding: 10px;text-align: left;" /> -->
                  <!-- <th style="padding: 10px;text-align: left;" /> -->

                  <th
                    colspan="2"
                  >
                    {{ $t('Actions') }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="isActive == true">
                <tr
                  v-for="(supplier,index) in getSuppliers"
                  :key="supplier.supplierId"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266"
                >
                  <td>
                    {{ getTotalItemsForSuppliers-(page-1)* pageSize-index }}.
                  </td>
                  <td>
                    {{ supplier.supplierName }}
                  </td>
                  <td>
                    {{ supplier.contactName }}
                  </td>
                  <td>
                    {{ supplier.contactTitle }}
                  </td>
                  <td>
                    +{{ countryCallingCode[supplier.countryCode] }} {{ supplier.phone }}
                  </td>
                  <td>
                    {{ supplier.email }}
                  </td>
                  <td>
                    {{ supplier.webSite }}
                  </td>
                  <td>
                    {{ supplier.doorNumber }}, {{ supplier.street }} / {{ supplier.postalCode }}, {{ supplier.city }} / {{ supplier.country }}
                  </td>
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <td
                    v-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                    @click="editItem(supplier)"
                  >
                    <p
                      v-b-toggle.supplier-edit-toggle
                      class="p-0 m-0 ml-50"
                    >
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td
                    style=" width: 0"
                    @click="deactivate(supplier)"
                  >
                    <p
                      v-b-modal.modal-deactivate
                      :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"
                      class="p-0 m-0 ml-50"
                    >
                      <toggle-button
                        :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"

                        :value="true"
                        :sync="true"
                        color="#242F6E"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="isActive == false">
                <tr
                  v-for="(supplier,index) in getSuppliers"
                  :key="supplier.supplierId"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266"
                >
                  <td>
                    {{ getTotalItemsForSuppliers-(page-1)* pageSize-index }}.
                  </td>
                  <td>
                    {{ supplier.supplierName }}
                  </td>
                  <td>
                    {{ supplier.contactName }}
                  </td>
                  <td>
                    {{ supplier.contactTitle }}
                  </td>
                  <td>
                    +{{ countryCallingCode[supplier.countryCode] }} {{ supplier.phone }}
                  </td>
                  <td>
                    {{ supplier.email }}
                  </td>
                  <td>
                    {{ supplier.webSite }}
                  </td>
                  <td>
                    {{ supplier.doorNumber }}, {{ supplier.street }} / {{ supplier.postalCode }}, {{ supplier.city }} / {{ supplier.country }}
                  </td>
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <!-- <td style="padding: 9px; text-align: left;" /> -->
                  <td
                    v-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                    @click="editItem(supplier)"
                  >
                    <p
                      v-b-toggle.supplier-edit-toggle
                      class="p-0 m-0 ml-50"
                    >
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td
                    style=" width: 0"
                    @click="activateSupp(supplier)"
                  >
                    <p
                      v-b-modal.supplier-activate
                      :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"
                      class="p-0 m-0 ml-50"
                    >
                      <toggle-button
                        :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"

                        :value="supplier.isActive"
                        :sync="true"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab
          :title="$t('Report')"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("OtherSuppliers") }}</strong>
          </template>
          <div
            style="width: 100%;  padding-top: 0px; margin-top: 0px;"
            class="scroll"
          >
            <div style="display: flex; justify-content: space-between; margin: 1% 0%;">
              <div v-if="isActive">
                <button
                  v-if="getLoggedInUser.role == 'StockManagerMaterialVariant' && client == 'WaloneFashion'"
                  v-b-modal.add-other-supplier
                  class="button"
                >
                  {{ $t('AddOtherSupplier') }}
                </button>
                <button
                  v-else-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                  v-b-modal.add-other-supplier
                  class="button"
                >
                  {{ $t('AddOtherSupplier') }}
                </button>
              </div>
              <div style="display: flex; justify-content: flex-end; gap: 2%; width: 100%;">
                <button
                  class="button"
                  @click="activeOther"
                >
                  {{ $t('Active') }}
                </button>
                <button
                  class="button"
                  @click="inActiveOther"
                >
                  {{ $t('Inactive') }}
                </button>
              </div>
            </div>
            <table
              ref="exportable_table"
              class="team_table"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t('SupplierName') }}
                  </th>
                  <th>
                    {{ $t('ContactPerson') }}
                  </th>
                  <th>
                    {{ $t('ContactTitle') }}
                  </th>
                  <th>
                    {{ $t('PhoneNumber') }}
                  </th>
                  <th>
                    {{ $t('SupplierEmail') }}
                  </th>
                  <th>
                    {{ $t('WebPage') }}
                  </th>
                  <th>
                    {{ $t('Address') }}
                  </th>
                  <th
                    colspan="2"
                  >
                    {{ $t('Actions') }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="isActive == true">
                <tr
                  v-for="(supplier) in getOtherSuppliers"
                  :key="supplier.supplierId"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266"
                >
                  <td>
                    {{ supplier.supplierName }}
                  </td>
                  <td>
                    {{ supplier.contactName }}
                  </td>
                  <td>
                    {{ supplier.contactTitle }}
                  </td>
                  <td>
                    +{{ countryCallingCode[supplier.countryCode] }} {{ supplier.phone }}
                  </td>
                  <td>
                    {{ supplier.email }}
                  </td>
                  <td>
                    {{ supplier.webSite }}
                  </td>
                  <td>
                    {{ supplier.doorNumber }}, {{ supplier.street }} / {{ supplier.postalCode }}, {{ supplier.city }} / {{ supplier.country }}
                  </td>
                  <td
                    v-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                    @click="editOtherSuppliers(supplier)"
                  >
                    <p
                      v-b-modal.edit-other-supplier
                      class="p-0 m-0 ml-50"
                    >
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td
                    style=" width: 0"
                    @click="deactivateOther(supplier)"
                  >
                    <p
                      v-b-modal.supplier-deactivate
                      :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"
                      class="p-0 m-0 ml-50"
                    >
                      <toggle-button
                        :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"

                        :value="true"
                        :sync="true"
                        color="#242F6E"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="!isActive">
                <tr
                  v-for="(supplier) in getOtherSuppliers"
                  :key="supplier.supplierId"
                  style="border-bottom: 1px solid #dcdfe6; color: #606266"
                >
                  <td>
                    {{ supplier.supplierName }}
                  </td>
                  <td>
                    {{ supplier.contactName }}
                  </td>
                  <td>
                    {{ supplier.contactTitle }}
                  </td>
                  <td>
                    +{{ countryCallingCode[supplier.countryCode] }} {{ supplier.phone }}
                  </td>
                  <td>
                    {{ supplier.email }}
                  </td>
                  <td>
                    {{ supplier.webSite }}
                  </td>
                  <td>
                    {{ supplier.doorNumber }}, {{ supplier.street }} / {{ supplier.postalCode }}, {{ supplier.city }} / {{ supplier.country }}
                  </td>
                  <td
                    v-if="getLoggedInUser.role !== 'StockManagerMaterialVariant'"
                    @click="editOtherSuppliers(supplier)"
                  >
                    <p
                      v-b-modal.edit-other-supplier
                      class="p-0 m-0 ml-50"
                    >
                      <b-icon-pencil />
                    </p>
                  </td>
                  <td
                    style=" width: 0"
                    @click="activateOther(supplier)"
                  >
                    <p
                      v-b-modal.supplierOther-activate
                      :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"
                      class="p-0 m-0 ml-50"
                    >
                      <toggle-button
                        :disabled="getLoggedInUser.role == 'StockManagerMaterialVariant'"

                        :value="supplier.isActive"
                        :sync="true"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
      <div v-if="tabIndex == 0">
        <b-pagination
          v-if="getTotalItemsForSuppliers > 15"
          v-model="page"
          :total-rows="getTotalItemsForSuppliers"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <div v-if="tabIndex == 1">
        <b-pagination
          v-if="getSuppliersItems > 15"
          v-model="page"
          :total-rows="getSuppliersItems"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <AddSupplierModal @supplier="addS" />
    <AddOtherSupplier @otherSupplier="addOther" />
    <EditRowModal
      :edit-supp="editSupp"
      @edit="editSup"
    />
    <EditOtherSupplier
      :edit-other-supp="editOtherSupp"
      @editOther="editOther"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <DeactivateModal
      :edit-id="deactivateItemId"
      @objData="deactivateId"
    />
    <ActivateModal
      :supplier-id="supplierId"
      @activeteSupplier="activeteSuppliers"
    />
    <DeactivateOtherSupplier
      :edit-other-id="deactivateOtherId"
      @inActiveOtherSupp="inActiveOtherSupp"
    />
    <ActivateOtherSupplier
      :edit-other-id="activateOtherId"
      @activeOtherSupp="activeOtherSupp"
    />
    <SupplierEditToggle
      :edit-supp="editSupp"
      @onCancelToggle="onCancelToggle"
      @edit="editSup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddSupplierModal from '@/modals/AddSupplierModal.vue';
import AddOtherSupplier from '@/components/supplier/AddOtherSupplier.vue'
import EditOtherSupplier from '@/components/supplier/EditOtherSupplier.vue'
import EditRowModal from '@/modals/EditRowModal.vue';
import DeactivateModal from '@/modals/DeactivateModal.vue';
import ActivateModal from '@/modals/ActivateModal.vue'
import DeactivateOtherSupplier from '@/components/supplier/DeactivateOtherSupplier.vue'
import ActivateOtherSupplier from '@/components/supplier/ActivateOtherSupplier.vue'
import SupplierEditToggle from '@/components/supplier/SupplierEditToggle.vue'
import { client } from '@/domainConfig'
import { countryCallingCodes } from '../../countryCodes'

/*  eslint-env es6  */
export default {
  components: {
    AddSupplierModal,
    EditRowModal,
    DeactivateModal,
    SupplierEditToggle,
    AddOtherSupplier,
    EditOtherSupplier,
    DeactivateOtherSupplier,
    ActivateOtherSupplier,
    ActivateModal,
  },
  props: {
    materialId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tabIndex: null,
      isSidebarOpen: false,
      client: client.clientName,
      countryCallingCode: countryCallingCodes,
      toggleSarch: false,
      show: true,
      searchCN: '',
      searchON: '',
      noSearch: false,
      noSearchTwo: false,
      searchByContactName: '',
      searchBySupplierName: '',
      isActive: true,
      name: null,
      editOtherSupp: null,
      supplierId: null,
      editSupp: {
        supplierId: '',
        supplierName: '',
        contactName: '',
        contactTitle: '',
        street: '',
        houseNumber: '',
        doorNumber: '',
        city: '',
        postalCode: '',
        country: '',
        countryCode: '',
        phone: '',
        email: '',
        webSite: '',
      },
      deactivateItemId: {},
      deactivateOtherId: {},
      activateOtherId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      rows: [],
      arrowCounter: 0,
      arrowCounter2: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getOtherSuppliers',
      'getSuppliersItems',
    ]),
    formattedPhoneNumber() {
      const countryCode = this.user.countryCode;
      const callingCode = this.countryCallingCode[countryCode];
      const phoneNumber = this.user.phoneNumber;
      return `+${callingCode} ${phoneNumber}`;
    },
  },
  watch: {
    page(value) {
      if (this.tabIndex == 0) {
        this.loadSuppliers({
          pageNumber: value,
          pageSize: this.pageSize,
          isActive: this.isActive,
        });
      } else if (this.tabIndex == 1) {
        this.suppliersOther({
          pageNumber: value,
          pageSize: this.pageSize,
          isActive: this.isActive,
          name: this.name,
        })
      }
    },
    searchBySupplierName(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadSuppliers({
            pageNumber: 1,
            pageSize: this.pageSize,
            supplierName: this.searchBySupplierName,
            contactName: this.searchByContactName,
            isActive: this.isActive,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.filterSuppliersBySN({
            supplierName: value,
            isActive: this.isActive,
          });
          this.noSearch = true
        }
      }, 500)
    },
    searchByContactName(value) {
      if (value == '') {
        this.removeLists()
        this.loadSuppliers({
          pageNumber: 1,
          pageSize: this.pageSize,
          supplierName: this.searchBySupplierName,
          contactName: this.searchByContactName,
          isActive: this.isActive,
        });
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.filterSuppliersByCN(value);
        this.noSearchTwo = true
      }
    },
    tabIndex(value, newValue) {
      if (value != newValue) {
        this.page = 1
      }
    },
  },
  mounted() {
    this.fetch()
    this.fetchOtherSuppliers()
  },
  methods: {
    ...mapActions([
      'loadSuppliers',
      'addSupplier',
      'editSupplier',
      'getSuppliersCompanyNameLikeORContactNameLike',
      'deactivateSupplierById',
      'changeLoadingtoTrue',
      'filterSuppliersBySN',
      'resetFilteredSupplierBySN',
      'filterSuppliersByCN',
      'resetFilteredSupplierByCN',
      'suppliersOther',
      'addOtherSupplier',
      'editOtherSupplier',
      'activateSupplier',
      'editOtherSupplierStatus',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.loadSuppliers({
          pageNumber: this.page,
          pageSize: this.pageSize,
          supplierName: this.searchBySupplierName,
          contactName: this.searchByContactName,
          isActive: this.isActive,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    async fetchOtherSuppliers() {
      try {
        await this.suppliersOther({
          pageNumber: this.page,
          pageSize: this.pageSize,
          isActive: this.isActive,
          name: this.name,
        })
          .then((res) => {
            console.log(res);
          })
      } catch (e) {
        console.log(e);
      }
    },
    activeSupplier() {
      if (this.tabIndex == 0) {
        this.loadSuppliers({ isActive: true, pageNumber: 1, pageSize: this.pageSize })
        this.isActive = true
      }
    },
    inActiveSupplier() {
      if (this.tabIndex == 0) {
        this.loadSuppliers({ isActive: false, pageNumber: 1, pageSize: this.pageSize })
        this.isActive = false
      }
    },
    activeOther() {
      if (this.tabIndex == 1) {
        this.suppliersOther({ isActive: true, pageNumber: 1, pageSize: this.pageSize })
        this.isActive = true
      }
    },
    inActiveOther() {
      if (this.tabIndex == 1) {
        this.suppliersOther({ isActive: false, pageNumber: 1, pageSize: this.pageSize })
        this.isActive = false
      }
    },
    activateSupp(value) {
      this.supplierId = value.supplierId
    },
    activeteSuppliers(value) {
      this.activateSupplier(
        {
          supplierId: value,
          successCallback: () => {
            this.loadSuppliers({
              pageNumber: 1,
              pageSize: this.pageSize,
              supplierName: null,
              contactName: null,
              isActive: this.isActive,
            });
          },
        },
      )
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredSuppliersBySN.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchBySn(this.getFilteredSuppliersBySN[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 < this.getFilteredSuppliersByCN.length - 1) {
        this.arrowCounter2 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 > 0) {
        this.arrowCounter2 -= 1;
        this.fixScrolling()
      }
    },


    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFilteredSuppliersByCN[this.arrowCounter2])
        this.removeLists()
        this.arrowCounter2 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling2() {
      const liH = this.$refs.options2[this.arrowCounter2].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer2.scrollTop = liH * this.arrowCounter2;
    },
    showCName() {
      this.toggleSarch = true;
      this.searchBySupplierName = '';
      this.show = false
      this.loadSuppliers({
        pageNumber: 1,
        pageSize: this.pageSize,
        supplierName: this.searchBySupplierName,
        contactName: this.searchByContactName,
        isActive: this.isActive,
      });
    },
    showSName() {
      this.toggleSarch = false;
      this.searchByContactName = '';
      this.show = true;
      this.loadSuppliers({
        pageNumber: 1,
        pageSize: this.pageSize,
        supplierName: this.searchBySupplierName,
        contactName: this.searchByContactName,
        isActive: this.isActive,
      });
    },
    async addS(obj) {
      await this.addSupplier({
        object: obj,
        successCallback: () => {
          this.loadSuppliers({
            pageNumber: 1,
            pageSize: this.pageSize,
            supplierName: this.searchBySupplierName,
            contactName: this.searchByContactName,
            isActive: this.isActive,
          });
        },
      });
    },
    async addOther(obj) {
      await this.addOtherSupplier({
        object: obj,
        successCallback: () => {
          this.suppliersOther({
            pageNumber: this.page,
            pageSize: this.pageSize,
            isActive: this.isActive,
            name: this.name,
          })
        },
      });
    },
    async inActiveOtherSupp(supplierId) {
      await this.editOtherSupplierStatus({
        supplierId,
        newStatus: false,
        successCallback: () => {
          this.suppliersOther({
            pageNumber: this.page,
            pageSize: this.pageSize,
            isActive: this.isActive,
            name: this.name,
          })
        },
      })
    },
    async activeOtherSupp(supplierId) {
      await this.editOtherSupplierStatus({
        supplierId,
        newStatus: true,
        successCallback: () => {
          this.suppliersOther({
            pageNumber: this.page,
            pageSize: this.pageSize,
            isActive: this.isActive,
            name: this.name,
          })
        },
      })
    },
    async deactivateId(id) {
      const oldId = this.deactivateItemId.supplierId;
      await this.deactivateSupplierById({
        oldId,
        id,
        successCallback: () => {
          this.loadSuppliers({
            pageNumber: this.getCurrentPageForSuppliers,
            pageSize: this.pageSize,
            supplierName: this.searchBySupplierName,
            contactName: this.searchByContactName,
            isActive: this.isActive,
          });
        },
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    editItem(item) {
      this.isSidebarOpen = true
      this.editSupp.supplierId = item.supplierId;
      this.editSupp.supplierName = item.supplierName;
      this.editSupp.contactName = item.contactName;
      this.editSupp.street = item.street;
      this.editSupp.contactTitle = item.contactTitle;
      this.editSupp.doorNumber = item.doorNumber;
      this.editSupp.houseNumber = item.houseNumber;
      this.editSupp.city = item.city;
      this.editSupp.postalCode = item.postalCode;
      this.editSupp.country = item.country;
      this.editSupp.countryCode = item.countryCode;
      this.editSupp.phone = item.phone;
      this.editSupp.email = item.email;
      this.editSupp.webSite = item.webSite;
      this.editUser.countryCode = item.countryCode;
    },
    editOtherSuppliers(value) {
      console.log(value)
      this.editOtherSupp = value;
    },
    async editSup(obj) {
      await this.editSupplier({
        object: obj,
        successCallback: () => {
          this.loadSuppliers({
            pageNumber: this.page,
            pageSize: this.pageSize,
            supplierName: this.searchBySupplierName,
            contactName: this.searchByContactName,
            isActive: this.isActive,
          });
        },
      });
    },
    async editOther(obj) {
      await this.editOtherSupplier({
        object: obj,
        successCallback: () => {
          this.suppliersOther({
            pageNumber: this.page,
            pageSize: this.pageSize,
            isActive: this.isActive,
            name: this.name,
          })
        },
      });
    },
    deactivate(obj) {
      this.deactivateItemId = obj;
    },
    deactivateOther(obj) {
      this.deactivateOtherId = obj;
    },
    activateOther(obj) {
      this.activateOtherId = obj;
    },
    async searchBySn(value) {
      this.searchBySupplierName = value
      this.noSearch = false
      await this.loadSuppliers({
        pageNumber: this.page,
        pageSize: this.pageSize,
        supplierName: this.searchBySupplierName,
        contactName: this.searchByContactName,
        isActive: this.isActive,
      });
    },
    async searchByCn(value) {
      this.searchByContactName = value
      this.noSearchTwo = false
      await this.loadSuppliers({
        pageNumber: this.page,
        pageSize: this.pageSize,
        supplierName: this.searchBySupplierName,
        contactName: this.searchByContactName,
        isActive: this.isActive,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.noSearchTwo = false;
      this.resetFilteredSupplierBySN();
      this.resetFilteredSupplierByCN();
    },
  },
};
</script>

<style scoped lang="scss">

// .hover-row:hover {
//     background-color: #f5f5f5; /* Set your desired background color here */
//   }
.active2 {
  font-weight: bold;
  color: $base-color;
}

.team_table td{
  padding: 16px 9px !important;
}

.team_table {
  width: 100%;
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }

}

.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
