<template>
  <div>
    <b-modal
      id="modal-1"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitSupplier')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('SupplierName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.supplierName.$model"
            :state="validateState('supplierName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="`${$t('ContactName')}:`"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.contactName.$model"
            :state="validateState('contactName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              $t('Must_be_at_least_3_characters') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="`${$t('ContactTitle')}:`"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            v-model="$v.form.contactTitle.$model"
            :state="validateState('contactTitle')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('Must_be_at_least_3_characters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-8"
          :label="`${$t('PhoneNumber')}:`"
          label-for="input-8"
        >
          <!-- <b-form-input
            id="input-8"
            v-model="$v.form.phone.$model"
            type="number"
            step="any"
            :state="validateState('phone')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_contains_9_numbers') }}</b-form-invalid-feedback> -->
          <vue-phone-number-input
            v-model="form.phone"
            default-country-code="XK"
            :error="
              isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false
            "
            :preferred-countries="['AL', 'CH', 'DE']"
            :no-example="true"
            @update="getFormattedNumber"
          />
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="`${$t('Email')}:`"
          label-for="input-9"
        >
          <b-form-input
            id="input-9"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              $t('Must_be_email_format') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="`${$t('WebSite')}:`"
          label-for="input-9"
        >
          <b-form-input
            id="input-9"
            v-model="$v.form.webSite.$model"
            :state="validateState('webSite')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('Must_be_at_least_3_characters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('Street')}:`"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="$v.form.street.$model"
            :state="validateState('street')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('Door/HouseNumber')}:`"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="$v.form.doorNumber.$model"
            required
            type="number"
            step="any"
            min="0"
            :state="validateState('doorNumber')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_1') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-6"
          :label="`${$t('PostalCode')}:`"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            v-model="$v.form.postalCode.$model"
            :state="validateState('postalCode')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="`${$t('City')}:`"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="$v.form.city.$model"
            required
            :state="validateState('city')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

      </b-form>
      <b-form-group
        id="input-group-7"
        :label="`${$t('Country')}:`"
        label-for="input-7"
      >
        <b-form-input
          id="input-7"
          v-model="$v.form.country.$model"
          :state="validateState('country')"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-invalid-feedback id="input-1-live-feedback">{{
          $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"

          :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null"
          @click="onSubmitSupplier"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="reset"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, minLength, numeric, minValue, email, alphaNum,
} from 'vuelidate/lib/validators';
// import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  mixins: [validationMixin],

  data() {
    return {
      isPhoneNumberValid: null,
      form: {
        supplierName: '',
        contactName: '',
        street: '',
        doorNumber: '',
        contactTitle: '',
        city: '',
        postalCode: '',
        country: '',
        countryCode: '',
        phone: '',
        email: '',
        webSite: '',
      },
    }
  },
  validations: {
    form: {
      supplierName: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      contactName: {
        // required,
        minLength: minLength(3),
        // alpha,
      },
      street: {
        required,
        minLength: minLength(1),
      },
      doorNumber: {
        // required,
        minLength: minValue(1),
        numeric,
      },
      contactTitle: {
        // required,
        minLength: minLength(2),
        // alpha,
      },
      city: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      postalCode: {
        required,
        minLength: minLength(3),
        alphaNum,
      },
      country: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      // phone: {
      //   required,
      //   minLength: minLength(9),
      //   numeric,
      // },
      email: {
        // required,
        email,
      },
      webSite: {
        // required,
        minLength: minLength(3),
        // alphaNum,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.form.phone = n.nationalNumber;
        this.form.countryCode = n.countryCode;
      } else {
        this.form.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    onSubmitSupplier() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('supplier', this.form)
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      // Reset our form values
      this.form.supplierName = ''
      this.form.contactName = ''
      this.form.street = ''
      this.form.contactTitle = ''
      this.form.city = ''
      this.form.doorNumber = ''
      this.form.postalCode = ''
      this.form.country = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.webSite = ''
      this.form.countryCode = '';
      // Trick to reset/clear native browser form validation state
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>

<style>
.blinori {
  background-color: #242F6E;
  color: white;
}

.blinori button {
  background-color: white;
  border-radius: 10px;
}

.leo {
  background-color: white;
  border-radius: 10px;
}

.form.control {
  width: 100%;
}
</style>
